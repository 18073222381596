import React from "react"
import { graphql, Link } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

const BlogTemplate = ({ data, pageContext }) => {
  const pageData = data.prismicPage.data
  const blogPosts = data.allPrismicBlogPost.edges
  const navigation = data.prismicNavigation
  const social = data.prismicSocial

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/news/" : "/news/" + (currentPage - 1).toString()
  const nextPage = "/news/" + (currentPage + 1).toString()

  return (
    <Layout
      altLang={data.prismicPage.alternate_languages}
      navigation={navigation}
      social={social}
    >
      <Seo
        title={
          pageData.meta_title ? pageData.meta_title : pageData.page_title.text
        }
        keywords={pageData.meta_keywords}
        description={pageData.meta_description}
        lang={pageContext.lang}
      />
      {pageData.page_title && (
        <div className="relative overflow-hidden mb-10 max-w-screen-xl w-full mx-auto featured fade sm:px-6">
          <div
            className="caption text-center p-4 md:p-6"
            dangerouslySetInnerHTML={{
              __html: get(pageData, "page_title.html"),
            }}
          />
        </div>
      )}
      <div className="max-w-5xl mx-auto relative px-4 sm:px-6 pb-4 sm:pb-8">
        <div className="relative">
          <ul>
            {blogPosts.map((blogpost, index) => {
              var imageSlice = blogpost.node.data.body.find(
                (image) => image.slice_type === "image"
              )
              let firstImage = imageSlice.items[0].image
              return (
                <li
                  key={`blogpost-${index}`}
                  className="rounded-xl bg-white transform translate ease duration-200 flex flex-1 mb-6 shadow-lg hover:shadow-lg hover:scale-105"
                >
                  <Link
                    className="flex flex-1 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50 rounded-xl"
                    to={blogpost.node.url}
                  >
                    <div className="w-1/3 md:w-1/4">
                      {firstImage?.fluid && (
                        <Img
                          className="relative overflow-hidden bg-gray-500 md:object-contain h-full w-full rounded-l-xl"
                          fluid={firstImage.fluid}
                          alt={firstImage.alt || ""}
                        />
                      )}
                    </div>
                    <div className="w-2/3 md:w-3/4 p-3 lg:p-6 flex flex-col flex-1">
                      <h2 className="text-2xl mt-0 mb-3">
                        {blogpost.node.data.title}
                      </h2>
                      <div>{blogpost.node.data.blog_introduction}</div>
                      <div className="text-blue-600 mt-auto pt-3 text-base">
                        <strong>
                          {blogpost.node.data.author || "Unicard"}
                        </strong>{" "}
                        {blogpost.node.data.first_publish_date ||
                          blogpost.node._meta?.first_publication_date}
                      </div>
                    </div>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="flex justify-center">
          {!isFirst && (
            <Link to={prevPage} className="button" rel="prev">
              ← Previous Page
            </Link>
          )}
          {!isLast && (
            <Link to={nextPage} className="button" rel="next">
              Next Page →
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogPost($skip: Int!, $limit: Int!, $lang: String!) {
    prismicNavigation(lang: { eq: $lang }) {
      ...PrismicNavigationFragment
    }
    prismicSocial(lang: { eq: $lang }) {
      ...PrismicSocialFragment
    }
    prismicPage(uid: { eq: "news" }, lang: { eq: $lang }) {
      ...PrismicPageFragment
    }
    allPrismicBlogPost(
      limit: $limit
      skip: $skip
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...PrismicBlogPostFragment
        }
      }
    }
  }
`

export default withPreview(BlogTemplate)
